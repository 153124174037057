<template>
    <div class="row header">
        <div class="col-md-12">
            <div class="container">
                <div class="row">
                    <div class="col-md-2" @mouseover="hoverLogo=true" @mouseleave="hoverLogo=false">
                        <router-link :to="LienLogo" >
                            <!-- Logo démo demo.pfv -->
                            <h1 v-if="getUrl().indexOf('demo.pfv')!=-1 && !hoverLogo" class="TxtRoseLgtrf logospv">DÉMO</h1>
                            <!-- Logo prod -->
                            <img v-else src="@/assets/imgs/logo-lagardere.svg" fluid class="logospv" />
                        </router-link>  
                    </div>
                        <div v-if="showDelegation" class="col-md-3 delegation">
                                {{ GetNomDelegation() }}
                            </div>
                    <div :class="'text-right ' + (showDelegation ? 'col-md-7' : 'col-md-10')">
                        <ul id="navlinks">
                            <li v-if="showAdminSpv" class="nav-item">
                                <router-link to="/admin" class="nav-link">ADMIN</router-link>
                            </li>
                            <li v-if="showMesDeclarations" class="nav-item">
                                <router-link to="/mes-declarations" class="nav-link">Déclarations</router-link>
                            </li>
                            <!-- <li v-if="showMesDocuments" class="nav-item">
                                <router-link to="/mes-documents" class="nav-link">Documents</router-link>
                            </li> -->
                            <li v-if="showPdv" class="nav-item">
                                <router-link to="/pdv-liste" class="nav-link">Points de vente</router-link>
                            </li>
                            <li v-if="showGerants" class="nav-item">
                                <router-link to="/gerant-liste" class="nav-link">Gérants</router-link>
                            </li>
                            <li v-if="showGestionUtilisateurs" class="nav-item">
                                <router-link to="/utilisateurs" class="nav-link">Utilisateurs</router-link>
                            </li>
                            <li v-if="showRelances" class="nav-item">
                                <router-link to="/relances" class="nav-link">Relances</router-link>
                            </li>
                            <li class="nav-item"
                                v-else-if="currentUser && currentUser?.role == 'adv' || currentUser?.role == 'directeurbu' || currentUser?.role == 'directeurregion' || currentUser?.role == 'adjointregion'">
                                <span class="nav-link">
                                    <Popper hover content="Présentation de la plateforme" placement="bottom"
                                        class="text-center">
                                        <img src="@/assets/imgs/Cursor_et_Relay-PFV_2023.png" class="navImgVideo"
                                            @click="ShowModalVideoADV = true" />
                                    </Popper>
                                </span>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link to="#" class="nav-link">
                                    <font-awesome-icon icon="bell" />
                                </router-link>
                            </li> -->
                            <li class="nav-item" v-if="currentUser">
                                <router-link to="/profile" class="nav-link">
                                    <Popper hover content="Mon compte" placement="bottom" class="text-center">
                                        <font-awesome-icon icon="user" />
                                    </Popper>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="logOut">
                                    <Popper hover content="Se déconnecter" placement="bottom" class="text-center">
                                        <font-awesome-icon icon="sign-out-alt" />
                                    </Popper>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Modal Video -->
    <vue-final-modal v-model="ShowModalVideoADV" classes="modal-container" content-class="modal-content large"
        @opened="playVideo()" @closed="stopVideo()">
        <div class="modal-header">
            <button class="modal__close" @click="ShowModalVideoADV = false">
                <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <span class="modal__title">Présentation de la plateforme</span>
        </div>
        <div class="modal-text">
            <div class="row">
                <div class="col-md-12 text-center">
                    <video class="videoPresentation" controls>
                        <source
                            src="https://www.pfv-declarations.fr/videos/LTRF_PFV_Presentation_ADV_nouvelle_plateforme_declaration_PFV.mp4"
                            type=video/mp4>
                    </video>
                </div>
            </div>
        </div>
    </vue-final-modal>




    <!-- End Modal Video -->
</template>

<script>
import { isSessionExpired } from "@/utilities";
export default {
    name: "Navigation",
    currentUser: {},
    data() {
        return {
            LienLogo: "/home",

            ShowModalVideoADV: false,
            hoverLogo:false,
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        showPdv() {
            if (this.currentUser && this.currentUser.listeModule && !this.adjointSansDirecteur) {
                return this.currentUser.listeModule.includes("pdvListe");
            }
            return false;
        },
        showDelegation() {
            return (this.currentUser 
            && (this.currentUser.role=="adjointregion" || this.currentUser.role=="directeurregion") 
            && (this.currentUser.delegationNomDirecteur?.length>0 || this.currentUser.delegationNomAdjoint?.length>0));
        },
        adjointSansDirecteur() {
           return (this.currentUser && this.currentUser?.role=="adjointregion" && (this.currentUser.delegationNomDirecteur == null || this.currentUser.delegationNomDirecteur?.length == 0)); 
        },
        showGerants() {
            if (this.currentUser && this.currentUser.listeModule && !this.adjointSansDirecteur) {
                return this.currentUser.listeModule.includes("gerantsListe");
            }
            return false;
        },
        showMesDeclarations() {
            if (this.currentUser && this.currentUser.listeModule && this.currentUser.role != 'gerant' && !this.adjointSansDirecteur) {
                return this.currentUser.listeModule.includes("declarationsListe");
            }
            return false;
        },
        // showMesDocuments() {
        //     if (this.currentUser && this.currentUser.listeModule) {
        //         return this.currentUser.listeModule.includes("mesDocuments");
        //     }
        //     return false;
        // },
        showAdminSpv() {
            if (this.currentUser && this.currentUser.role == 'admin' && this.currentUser.email.indexOf("@spv.fr")!=-1) {
                return true;
            }
            return false;
        },
        showRelances() {
            if (this.currentUser && this.currentUser.listeModule) {
                return this.currentUser.listeModule.includes("relances");
            }
            return false;
        },
        showGestionUtilisateurs() {
            if (this.currentUser && this.currentUser.listeModule) {
                return this.currentUser.listeModule.includes("gestionMesUtilisateurs");
            }
            return false;
        }
    },
    props: {
        pageName: String,
    },
    methods: {
        getUrl(){
            return window.location.href;
        },
        GetNomDelegation() {
            if (this.currentUser?.role=="adjointregion" && this.currentUser?.delegationNomDirecteur) {
                return "Compte de " + this.currentUser?.delegationNomDirecteur.join(", ");
            } else if (this.currentUser?.role=="directeurregion" && this.currentUser?.delegationNomAdjoint) {
                return "Compte délégué à " + this.currentUser?.delegationNomAdjoint.join(", ");
            } else {
                return "";
            }
        },
        playVideo() {
            document.getElementsByClassName('videoPresentation')[0].play();
        },
        stopVideo() {
            document.getElementsByClassName('videoPresentation')[0].pause();
        },
        logOut() {
            localStorage.setItem("user", null);
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
    },
    mounted: function () {
        var user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        } else {
            if (isSessionExpired()) {
                localStorage.setItem("user", null);
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            }
            if (user.role != "gerant") {
                this.LienLogo = "/mes-declarations"
            }
        }

    }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/popper";

#navlinks li {
    display: inline-block;
    font-size: 0.8em;

    .nav-link {
        cursor: pointer;
        color: $bleu-nuit;
        text-transform: uppercase;
    }
}

video {
    width: 100%;
    height: auto;
}

.header {
    padding: 12px 0 3px 0;
    -webkit-box-shadow: 0 7px 9px -7px rgba(0, 0, 0, 0.4);
    box-shadow: 0 7px 9px -7px rgba(0, 0, 0, 0.4);
    background-color: #FFF;
    z-index: 1000;
}

.navImgVideo {
    height: 20px;
    width: auto;
}

.delegation {
    padding-top: 8px;
    font-size: 0.8em;
}
</style>